import { useCookie } from './Session'

export const note = (message: string) => {
  const [userGuid] = useCookie('userGuid')
  const g = userGuid()

  if (!g) {
    console.log('User Guid not initialized, sending anonymous note')
  }

  fetch('/api/note', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userGuid: g,
      message,
      url: window.location.href
    })
  })
}

export const init = () => {
  const [userGuid] = useCookie('userGuid')
  const g = userGuid()

  fetch('/api/note', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userGuid: g,
      screen: {
        w: window.screen.width,
        h: window.screen.height,
      },
      url: window.location.href
    })
  })
}